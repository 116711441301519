import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';

interface IExamQuestionStatement {
    statement: any
}

export default function ExamQuestionStatement(props: IExamQuestionStatement) {

    useEffect(() => {
        if (props.statement && props.statement.question) {
            const element = document.getElementById("statement-text-question");
            if (element) {
                element.innerHTML = props.statement.question
            }
        }
    }, [props.statement])

    return (
        <>
            <div className='ms-2 px-2'>
                <Card className='dcm-exam-list-card p-1 fw-bold' style={{fontSize: '18px'}}>
                    <div className="mx-2">
                        <span className='text-secondary pt-3'>Que: </span>
                        <span className='pb-3'>
                            <span id="statement-text-question"></span>
                        </span>
                    </div>
                </Card>
            </div>
        </>
    )
}