import React, { useEffect, useState } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import LiveExam from '../../Services/liveexam.services';
import './ExamQuestionAsImage.css'

interface IExamQuestionImage {
    value: any
}

export default function ExamQuestionAsImage(props: IExamQuestionImage) {
    const [s3Url, setS3Url] = useState<string>("");
    const [imageLoading, setImageLoading] = useState<boolean>(false);
    const [image, setImage] = useState<any>();

    const requestS3Url = async () => {
        await LiveExam.getS3url().then((res) => {
            if (res.status === 200) {
                setS3Url(res.data);
                let data = fetchImage(res.data + "/" + props.value?.[0]?.questionData?.[0]);
                data.then((res) => {
                    setImage(res);
                })
            }
        })
    }

    const fetchImage = async (url: string) => {
        setImageLoading(true)
        try {
            const response = await fetch(url);
            const data = await response.text();
            return data.startsWith('data:') ? data : `data:image/jpeg;base64,${data}`;
        } catch (error) {
            console.error('Error fetching image:', error);
            return null;
        } finally {
            setImageLoading(false)
        }
    };

    useEffect(() => {
        requestS3Url();
    }, [props.value]);

    return (
        <>
            <div className='ms-2 px-2'>
                <Card className='dcm-exam-list-card p-1'>
                    {
                        imageLoading ?
                            <div className="d-flex justify-content-center">
                                Loading... <Spinner animation="border" variant="primary" />
                            </div> :
                            <div className="mx-2">
                                <p className='text-secondary pt-3 pb-0'>Que: </p>
                                {/* <span className='pb-3'>
                                    <span id="statement-text-question"></span>
                                </span> */}

                                <img src={image} alt="image" className='img-fluid questionAsImage' draggable={false} loading="lazy" />
                            </div>
                    }
                </Card>
            </div>
        </>
    )
}